import * as React from "react";
import { HeadProps, PageProps, graphql, navigate } from "gatsby";

import builder, { BuilderComponent } from "@builder.io/react";
import { getBuilderFaqs } from "@utils/getBuilderFaqs";
import { handleContentLoaded } from "@utils/builderContentLoaded";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";
import FAQSchema from "@components/FAQSchema";
import MainLayout from "@layouts/main";
import SEOMeta from "@components/SEOMeta";
import withCalculatorProvider from "@components/HOC/withCalculatorProvider";

import "@builder.io/widgets";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";

import "../../libs/builder.setting";
import { RegisterHomepageLegacy } from "@components/BuilderWrapperComponents/HomepageLegacy";
import { useCallback, useEffect, useState } from "react";
import useDevice from "../../hooks/useDevice";
import { Cookie } from "../../utils/Cookie";
import { segmentExperimentStarted } from "../../utils/segment/experimentStarted";
import { segmentPageView } from "../../utils/handleOnRouteUpdate";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const PageFullNav: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const [content, setContent] = useState<
    Queries.builder_StaticPageFullNav["content"] | null
  >(null);
  const device = useDevice();

  const getContent = useCallback(() => {
    const isCohort = Cookie.checkCookie("RedesignCohort")
      ? parseInt(Cookie.getCookie("RedesignCohort") as string) === 2
        ? true
        : false
      : false;
    builder
      .get("page-full-nav", {
        userAttributes: {
          urlPath: location.pathname,
          device,
          customer: Boolean(Cookie.getCookie("sp_last_access")),
          redesignCohort: isCohort,
        },
        options: {
          noTraverse: false,
          includeRefs: true,
        },
      })
      .toPromise()
      .then(res => {
        if (!res) navigate("/404/");

        setContent(res);
      });
  }, [location.pathname, device]);

  useEffect(() => {
    if (
      sessionStorage.getItem("pageCount") === "0" &&
      window.location.pathname === "/"
    ) {
      if (!Cookie.checkCookie("RedesignCohort")) {
        const cohort = Math.random() > 0.5 ? 1 : 2;
        Cookie.setCookie("RedesignCohort", cohort.toString(), 365);
        let intervalCounter = 0;
        const myInterval = setInterval(() => {
          if (Cookie.checkCookie("RedesignCohort") && window.analytics?.track) {
            segmentPageView({ pathname: "/" } as Location);
            getContent();
            if (!Cookie.checkCookie("ExperimentExclude")) {
              segmentExperimentStarted(
                location.pathname,
                "Website Redesign MVP",
                {
                  variant:
                    Cookie.getCookie("RedesignCohort") === "1"
                      ? "Original"
                      : "Variant",
                }
              );
            }
            clearInterval(myInterval);
          }
          intervalCounter++;
          if (intervalCounter >= 20) {
            getContent();
            clearInterval(myInterval);
          }
        }, 10);
      } else {
        getContent();
      }
    } else {
      getContent();
    }
  }, [getContent, location.pathname]);

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  const isRebrandNav = content?.data?.rebrandPage;

  return (
    <MainLayout
      preventRerender
      pathname={location.pathname}
      isRebrandNav={isRebrandNav}
    >
      {content && (
        <BuilderComponent
          content={content}
          contentLoaded={handleContentLoaded}
          model="page-full-nav"
        />
      )}
    </MainLayout>
  );
};

RegisterComponentLibrary();
RegisterHomepageLegacy();

export default withCalculatorProvider(PageFullNav);

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePageFullNav },
  } = data;
  const seoData = onePageFullNav?.content
    ?.data as Queries.builder_PageFullNavData;

  const content = onePageFullNav?.content;
  const faqs = getBuilderFaqs(content);

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />

      {faqs && <FAQSchema faqsArray={faqs} />}

      {seoData?.json && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...seoData.json,
            }),
          }}
        />
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePageFullNav(
        target: { urlPath: $path }
        options: { cachebust: false, noTraverse: false, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
