import { BuilderBlock, FaqsOptions } from "@types";

export function getBuilderFaqs(content: any) {
  if (!content) return null;

  if (content?.data?.blocks) {
    const foundFaq: BuilderBlock<FaqsOptions> = content?.data?.blocks.find(
      (block: BuilderBlock) => {
        return block?.component?.name === "faqs-accordion";
      }
    );
    const faqsContent = foundFaq?.component?.options?.faqsContent;
    const formatedFaqs =
      faqsContent &&
      faqsContent.map(content => {
        return {
          faqsC: {
            questionsAnswer: content?.faq?.value?.data?.answer,
          },
          title: content?.faq?.value?.data?.question!,
        };
      });

    return formatedFaqs as Queries.WpFaq[];
  }
}
