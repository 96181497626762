import React from "react";
import { PageProps } from "gatsby";

import { CalculatorProvider } from "@contexts/CalculatorContext";
import { PricingCalculator } from "@components/PricingCalculator";

export default function withCalculatorProvider<T extends PageProps = PageProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const Component = (props: T) => {
    const isPricing = props.location.pathname.includes("pricing");

    const withProvider = isPricing ? (
      <CalculatorProvider isSoloPage>
        <WrappedComponent {...(props as T)} />

        <PricingCalculator />
      </CalculatorProvider>
    ) : (
      <WrappedComponent {...(props as T)} />
    );

    return withProvider;
  };

  return Component;
}
